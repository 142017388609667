import './App.css';
import { React } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Register from './pages/register';
import Messages from './pages/messages';
import Login from './pages/login';
import { Account } from './components/Account';
import Status from "./components/Status";
import PushPrompt from "./pages/pushprompt";

function App() {

  const checkPermission = () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error("No service worker support");
    } else {
      console.log("Service workers are supported!");
    }
  
    if(!('Notification' in window)) {
      throw new Error("No notifications for this browser");
    }
  }
  
  const registerSW = async () => {
    const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceWorker.js`);
    navigator.serviceWorker.ready.then((registration) => {
      console.log(registration);
      /*
      registration.active.postMessage(
        "cbtidball@gmail.com"
      );
      */
    });
    return registration;
  }
  
  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
  
    if(permission !== 'granted') {
      throw new Error("Notification permission not granted");
    } else {
      console.log("Notifications granted");
    }
  }
  
  const main = async () => {
    checkPermission();
    await requestNotificationPermission();
    await registerSW();
  }

  return (
      <div className="App">
        <Account>
          <Router>
            <Route exact path="/">
                <Messages/>
            </Route>
            <Route path="/home">
                <Messages/>
            </Route>
            <Route path="/messages">
                <Messages/>
            </Route>
            <Route path="/pushprompt">
                <PushPrompt main={main}/>
            </Route>
            <Route path="/signup">
                <Register/>
            </Route>
            <Route path="/register">
                <Register/>
            </Route>
            <Route path="/login">
                <Status/>
                <Login/> 
            </Route>
          </Router>
        </Account>
      </div>
  );
}

export default App;
