import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button'; 
import CopyrightFooter from '../components/copyrightFooter';

function PushPrompt({main}) {
  let history = useHistory();
  
  const handleClick = (event) => {
    main().then(() => {
      console.log(`Notifications enabled.`);
    }).catch((error) => {
      console.log(`Notifications error: ${error}`);
    }).finally(() => {
      history.push('./messages');
    });
  };

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Please give this app permission to receive push notifications
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}  
              onClick={handleClick} 
            >
              Let's do this!
            </Button>
          </Box>
        </Box>
        <CopyrightFooter sx={{ mt: 5 }} />
      </Container>
  );
}

export default PushPrompt;