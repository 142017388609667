import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const CopyrightFooter = (props) => {
  return (
    <div>
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="./home">
                Message Garden
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    </div>
  );
};
export default CopyrightFooter ;
