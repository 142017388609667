import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountContext } from "../components/Account";
import Message from "../components/Message";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';

const themey = createTheme({
    palette: {
        primary: {
            light: '#3E5C76',
            main: '#1D2D44',
            dark: '#0D1321',
            contrastText: '#fff',
        },
        secondary: {
            light: '#748CAB',
            main: '#EF3E36',
            dark: '#ba000d',
            contrastText: '#000',
        }
    },
});

function Messages() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getSession, logout } = useContext(AccountContext);
  const [messageArray, setMessageArray] = useState([]);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuLogout = () => {
    logout();
    handleClose();
    history.push("/login");
  }

  const menuRegister = () => {
    console.log("register");
  }

  const menuLogin = () => {
    history.push("/login");
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMessages = async(user_id, accessToken) => {
    // Get all messages
    setMessageArray([]);
    const apiURL = `https://tknusgxup9.execute-api.us-west-2.amazonaws.com/Production/messages?user_id=${user_id}`;
    const response = await fetch(apiURL,
      { 
        method: 'GET', 
        headers: { 
            'Content-type': "application/json",
            'cogauth': accessToken
        }
      }
    );
    const messageData = await response.json();
    setMessageArray(messageData);
  }

  useEffect(() => { 
    getSession().then((session) => {
      getMessages(session.idToken.payload.email, session.idToken.jwtToken); 
    }).catch((err) => {
      console.log("No session found, err: ", err);
      history.push("/login");
    });
  }, [] );

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={themey}>
      <Paper square sx={{ pb: '50px' }}>
        <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
          <h2> Message Garden</h2>
        </Typography>
        <List>
          {messageArray.map((msg, i) => <Message msg={msg} key={i} />)}
        </List>
      </Paper>
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <IconButton 
            color="inherit" 
            aria-label="open drawer"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={menuRegister}>Register</MenuItem>
            <MenuItem onClick={menuLogin}>Login</MenuItem>
            <MenuItem onClick={menuLogout}>Logout</MenuItem>
          </Menu>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit" >
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
    </React.Fragment>
  );

}

  

export default Messages;