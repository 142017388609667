import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
const moment = require('moment-timezone');

const Message = ({msg}) => {
    const title = `${msg.title} [${moment(msg.sent).format('MMM Do, h:mm:ss a')}]`;
    const message = `${msg.message}`;

    return (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={title} secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {message} 
              </Typography>
            </React.Fragment>
          }/>
        </ListItem>
    );
  };
export default Message ;

